export default [
    {
        path: "/",
        name: "APP",
        meta: { title: "应用",menu:true },
        component: () => import("@/jp01/h5/layout.vue"),
        redirect:{name:"Index"},
        children:[{
                path: "index.html",
                name: "Index",
                meta: { title: "精选",menu:true },
                component: () => import("@/jp01/h5/index/layout.vue"),
            },{
                path: "topic.html",
                name: "Topic",
                meta: { title: "专题",menu:true },
                component: () => import("@/jp01/h5/topic/index.vue"),
            },{
                path: "topic/info.html",
                name: "TopicInfo",
                meta: { title: "专题详细",menu:false },
                component: () => import("@/jp01/h5/topic/info.vue"),
            },{
                path: "people.html",
                name: "People",
                meta: { title: "明星",menu:true },
                component: () => import("@/jp01/h5/star/people.vue"),
            },{
                path: "star.html",
                name: "Star",
                meta: { title: "明星",menu:false },
                component: () => import("@/jp01/h5/star/star.vue"),
            },{
                path: "/star/:id.html",
                name: "StarInfo",
                meta: { title: "明星详细",menu:false },
                component: () => import("@/jp01/h5/star/info.vue"),
            },{
                path: "/cate/:cid.html",
                name: "CateList",
                meta: { title: "类型",menu:false },
                component: () => import("@/jp01/h5/index/layout.vue"),
            },{
                path: "/cate/:cid/:id.html",
                name: "VodInfo",
                meta: { title: "视频详细",menu:false },
                component: () => import("@/jp01/h5/vod/info.vue"),
            },{
                path: "search.html",
                name: "Search",
                meta: { title: "搜索",menu:false },
                component: () => import("@/jp01/h5/search.vue"),
            },{
                path: "menber.html",
                name: "Member",
                meta: { title: "会员中心",menu:false },
                component: () => import("@/jp01/h5/menber/index.vue"),
            },{
                path: "discovery.html",
                name: "Discovery",
                meta: { title: "发现",menu:false },
                component: () => import("@/jp01/h5/discovery/index.vue"),
            }
        ]
    },{
        path: "/:pathMatch(.*)*",
        name: "404",
        meta: { title: "404",menu:false },
        component: () => import("@/jp01/h5/404.vue"),
    }
];