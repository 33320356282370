<template>
	<ul>
		<template v-for="(vo,idx) in listData">
			<li>
				<router-link :to="{name:'VodInfo',params:{cid:vo.categoryChildId,id:vo.id}}">
					<div class="cont">
						<img :src="vo.surfacePlot" :key="vo.id" />
						<!-- <span class="vip" v-if="vo.tag == 'vip'">VIP</span>
						<span class="pay" v-else-if="vo.tag != ''">{{vo.tag}}</span>
						<span class="free" v-else>免费</span> -->
						<span class="free">免费</span>
						<span :class="{'date':true,'on':vo.releaseTime >= $store.state.dayStart}" v-if="$store.state.siteInfo.mode">
							{{$Time.format("yy-mm-dd",vo.releaseTime)}}
						</span>
						<span class="time">{{vo.formatDuration}}</span>

						<div class="tip">
							<i :style="'background-image: url('+$Svg('video.svg')+')'">{{vo.watchNums}}</i>
							<i :style="'background-image: url('+$Svg('hot1.svg')+')'">{{vo.popularity}}</i>
						</div>
						<div class="clear"></div>
					</div>
					<p :title="vo.name">{{vo.name}}</p>
				</router-link>
			</li>
		</template>
	</ul>
</template>
<script setup>
	import { computed,reactive, toRefs, ref, getCurrentInstance, onMounted, nextTick, watch } from "vue";
	import { useRouter } from "vue-router";
	let router = useRouter();
	const props = defineProps({
		listData: { default: [] },
	})
	const FN = {
		// toPlayer(item){
		// 	if(item.categoryChildId){
		// 		router.push({name:'VodInfo',params:{cid:item.categoryChildId,id:item.id}})
		// 	}
		// }
	}
	onMounted(() => {
	})
</script>
<style lang='scss' scoped>
ul{
	display: grid;
	grid-template-columns: calc(50% - 5px) calc(50% - 5px);
	grid-gap: 10px;
	li{
		list-style-type: none;
		cursor: pointer;
		&:nth-last-child(1){ margin-bottom: 0; }
		&:nth-last-child(2){ margin-bottom: 0; }
		&:nth-last-child(3){ margin-bottom: 0; }
		&:nth-last-child(4){ margin-bottom: 0; }
		.cont{
			position: relative;
			width: 100%;
			font-size: 0;
			&:before{
				content: "";
				background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #1c1c1c 80%);
				border-radius: 0px 0px 6px 6px;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 24px;
			}
			img{
				width: 100%;
				border-radius: 5px;
				height: calc(100vw / 2 * 9 /16);
			}
			.clear{
				clear: both;
				overflow: hidden;
				font-size: 0;
			}
			span{
				position: absolute;
				font-size: 12px;
				display: inline-block;
				color: #fff;
				&.vip{
					right: 0;
					top: 0;
					padding:2px 5px;
					font-weight: 600;
					background-image: var(--vip);
					border-radius: 0px 0px 0px 5px
				}
				&.pay{
					right: 0;
					top: 0;	
					padding:2px 5px;
					// background: #FF6D32;
					background-image: var(--pay);
					border-radius: 0px 0px 0px 5px;
				}
				&.date{
					left: 0;
					top: 0;	
					padding:2px 5px;
					background: var(--date);
					border-radius: 0px 0px 5px 0px;
					&.on{
						color:red;
						background: #fff;
					}
				}
				&.time{
					right: 0;
					bottom: 0;	
					padding:2px 5px;
					// background: rgba(72,58,58,0.45);
					// border-radius: 5px 0px 5px 0px;
				}
				&.free{
					right: 0;
					top: 0;
					padding:2px 5px;
					background-image: var(--free);
					border-radius: 0px 0px 0px 5px
				}
			}
		}
		p{
			margin-top: 4px;
			margin-bottom: 8px;
			padding:0;
			font-size: 13px;
			color: #222222;
			display: block;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		// .desc{
		// 	padding:2px 5px 0 5px;
		// 	font-size: 10px;
		// 	color: #999;
		// 	display: -webkit-box;
		// 	overflow: hidden;  
		// 	-webkit-line-clamp: 2;
		// 	-webkit-box-orient: vertical;
		// }
		.tip{
			display: flex;
			align-items: baseline;
			font-size: 0;
			font-weight: normal;
			color: #fff;
			position: absolute;
			left: 3px;
			bottom: 3px;
			// background-color: #eee;
			i{
				position: relative;
				font-size:12px;
				font-style: normal;
				display: inline-block;
				padding-left: 18px;
				margin-right: 10px;
				background-size: auto 90%;
				background-repeat:no-repeat;
				background-position: left top;

			}
		}
	}
}
</style>