function loadSvg(filename) {
	// try{
	// 	return require("@/jp01/static/debug/" + filename)
	// }catch(err){
	// 	return require("@/jp01/static/debug/gray.png")
	// }
	return require("@/jp01/static/ico/" + filename)
}

import { createApp } from 'vue'
import App from '@/jp01/App.vue'
import router from '@/jp01/router'
import init from '@/lib/js/public.js'

//引入组件样式
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import lazyPlugin from 'vue3-lazy'

//引入其他文件
import '@/jp01/static/css/element.css'
import '@/jp01/static/css/global.scss'

const app = createApp(App)

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	if(key == "Position" || key == "footer" || key == "header") continue
  	app.component(key, component)
}

import WebHeader from '@/jp01/web/components/header.vue'
import WebFooter from '@/jp01/web/components/footer.vue'
import WebVodList from '@/jp01/web/components/vodlist.vue'
app.component('WebHeader', WebHeader)
app.component('WebFooter', WebFooter)
app.component('WebVodList', WebVodList)

import H5Header from '@/jp01/h5/components/header.vue'
import H5Footer from '@/jp01/h5/components/footer.vue'
import H5VodList from '@/jp01/h5/components/vodlist.vue'
import H5Page from '@/jp01/h5/components/page.vue'
import H5Back from '@/jp01/h5/components/back.vue'
app.component('H5Header', H5Header)
app.component('H5Footer', H5Footer)
app.component('H5VodList', H5VodList)
app.component('H5Page', H5Page)
app.component('H5Back', H5Back)

	window.requestAnimFrame = (function () {
	return window.requestAnimationFrame ||
	  window.webkitRequestAnimationFrame ||
	  window.mozRequestAnimationFrame ||
	  window.oRequestAnimationFrame ||
	  window.msRequestAnimationFrame ||
	  function (callback) {
	    window.setTimeout(callback, 1000 / 60);
	  };
	})();

	let recordClient = phoneClient()
	function secTimer() {
		if(phoneClient() != recordClient){
			location.reload()
			return
		}
		window.requestAnimFrame(secTimer);
	}
	secTimer()


//按需进行请求挂载
init(app,{
    "protocol": "",
    "hostname": "",
    "port": 0
}).then(store=>{
	app.config.globalProperties.$Svg = loadSvg;
	
	app.use(store).use(router).use(ElementPlus, {
		locale: zhCn,
	}).use(lazyPlugin, {
		loading: loadSvg('loading.svg'),
		delay: 15 * 1000,
		error: loadSvg('nopic.svg')
	}).mount('#app_jp01')

}).catch(err=>{
    // console.clear()
    console.dir(err)
})