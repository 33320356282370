<template>
	<ul>
		<template v-for="(vo,idx) in listData">
			<li>
				<router-link :to="{name:'VodInfo',params:{cid:vo.categoryChildId,id:vo.id}}">
					<div class="cont" :title="vo.name">
						<img :src="vo.surfacePlot || vo.screenshot" :key="vo.id" />
						<!-- <span class="vip" v-if="vo.tag == 'vip'">VIP</span>
						<span class="pay" v-else-if="vo.tag != ''">{{vo.tag}}</span>
						<span class="free" v-else>免费</span> -->
						<span class="free">免费</span>
						<span :class="{'date':true,'on':vo.releaseTime >= $store.state.dayStart}" v-if="$store.state.siteInfo.mode">
							{{$Time.format("yy-mm-dd",vo.releaseTime)}}
						</span>
						<span class="time">{{vo.formatDuration}}</span>
						<div class="tip">
							<i :style="'background-image: url('+$Svg('video.svg')+')'">{{vo.watchNumber}}</i>
							<i :style="'background-image: url('+$Svg('hot1.svg')+')'">{{vo.hot}}</i>
						</div>
						<div class="clear"></div>
					</div>
					<p :title="vo.name">{{vo.name}}</p>
				</router-link>
			</li>
		</template>
	</ul>
</template>
<script setup>
	import { computed,reactive, toRefs, ref, getCurrentInstance, onMounted, nextTick, watch } from "vue";
	import { useRouter } from "vue-router";
	let router = useRouter();
	const props = defineProps({
		listData: { default: [] },
	})
	const FN = {
		// toPlayer(item){
		// 	if(item.categoryChildId){
		// 		router.push({name:'VodInfo',params:{cid:item.categoryChildId,id:item.id}})
		// 	}
		// }
	}
	onMounted(() => {
	})
</script>
<style lang='scss' scoped>
ul{
	display: grid;
	grid-template-columns: calc(25% - 8px) calc(25% - 7px) calc(25% - 7px) calc(25% - 8px);
	grid-gap: 10px;
	li{
		list-style-type: none;
		cursor: pointer;
		&:nth-last-child(1){ margin-bottom: 0; }
		&:nth-last-child(2){ margin-bottom: 0; }
		&:nth-last-child(3){ margin-bottom: 0; }
		&:nth-last-child(4){ margin-bottom: 0; }
		
		p{
			margin-top: 4px;
			margin-bottom: 8px;
			padding:0;
			font-size: 13px;
			color: #222222;
			display: block;
			// overflow: hidden;
			// white-space: nowrap;
			// text-overflow: ellipsis;
			display: -webkit-box;
			overflow: hidden;  
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
		}
		// .desc{
		// 	padding:2px 5px 0 5px;
		// 	font-size: 10px;
		// 	color: #999;
		// 	display: -webkit-box;
		// 	overflow: hidden;  
		// 	-webkit-line-clamp: 2;
		// 	-webkit-box-orient: vertical;
		// }
	}
}
</style>