<template>
	<div class="app">
		<router-view :key="$store.state.pageKey"></router-view>
	</div>
</template>
<script setup>
	import md5 from 'md5'
	import { reactive, toRefs, ref, getCurrentInstance, onMounted, nextTick, watch } from "vue";
	
	import { useStore } from "vuex";
	const store = useStore();

	//获取上下文
	const currentInstance = getCurrentInstance()
	const { $ajax } = currentInstance.appContext.config.globalProperties
	
	// api
	import useApi from  '@/lib/js/api.js'
	let api = useApi($ajax)

	const state = reactive({
		pageKey:""
	})

	onMounted(() => {
		api.getClassList({type:1}).then(res=>{
			store.commit("SiteClass",res.data || [])
		}).catch(err=>{

		})
	})

	import { useRouter } from "vue-router";
	let router = useRouter();

	// const FN = {
	// 	toTop(){
	// 		$("body,html").animate({scrollTop: 0},10);
	// 	}
	// }

	watch(()=>router,(n,o)=>{
		try{
			let path = router.currentRoute.value.fullPath || ""
			let key = md5(path)
			if(state.pageKey != key){
				state.pageKey = key
				store.commit("PageKey")
			}
		}catch(e){

		}
	},{immediate:true,deep:true})
</script>
<style>
*{
  box-sizing: border-box;
}
body{
	background:#fff;
	position: relative;
}
.app{
	background-color: #fff;
}
</style>
