import {createRouter,createWebHistory,createWebHashHistory} from "vue-router";
import h5Routes from './h5'
import webRoutes from './web'

function isMobile() {
	let ua = navigator.userAgent.toLowerCase();
	if (/android/i.test(ua)) {  //判断安卓
		return true
	}
	if (/ipad|iphone|ipod/.test(ua) && !window.MSStream) {  //判断苹果
		return true
	}
	return false
}


export const AppRouter = function(){
	return isMobile() ? h5Routes:webRoutes
}

export default createRouter({
	history: typeof apiprefix == 'string' ? createWebHashHistory():createWebHistory(),
    // history: preview ? createWebHashHistory():createWebHistory(),
    scrollBehavior: () => ({
        y: 0
    }),
    routes: AppRouter()
})