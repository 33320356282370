<template>
	<template v-if="$route.name != 'Member'"> 
        <Position position="foot" />
	</template>
	<footer>
		<div class="layout">
			<!-- 本站资源由 <a href="{{$store.state.siteInfo.TechnicalSupport}}" target="_blank" class="tec" style="color:red">{{$store.state.siteInfo.TechnicalSupport}}</a> 提供 -->
			<!-- <p>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
			</p>
			<div class="desc">本站内容均从互联网收集而来，仅供交流学习使用，版权归原创者所有如有侵犯了您的权益，尽请通知我们，本站将及时删除侵权内容。</div>
			<div class="copyright">Copyright @ 2022 XXXX 版权所有</div> -->
	        <Position position="indexfloat" v-if="$route.name == 'Index'" />
	        <Position position="float" v-else />
		    <div class="clear"></div>
		</div>
		<ul class="menu">
			<li :class="{'on':$route.name == 'Index' || $route.name == 'CateList'}">
				<router-link :to="{name:'Index'}">
					<div class="svg">
						<img :src="$Svg('home.svg')" />
					</div>
					<p>首页</p>
				</router-link>
			</li>
			<li :class="{'on':$route.name == 'Discovery'}">
				<router-link :to="{name:'Discovery'}">
					<div class="svg">
						<img :src="$Svg('faxian.svg')" />
					</div>
					<p>发现</p>
				</router-link>
			</li>
			<li :class="{'on':$route.name == 'Topic'}">
				<router-link :to="{name:'Topic'}">
					<div class="svg">
						<img :src="$Svg('theme.svg')" />
					</div>
					<p>专题</p>
				</router-link>
			</li>
			<li :class="{'on':$route.name == 'People' || $route.name == 'Star'  || $route.name == 'StarInfo'}">
				<router-link :to="{name:'People'}">
					<div class="svg">
						<img :src="$Svg('star.svg')" />
					</div>
					<p>明星</p>
				</router-link>
			</li>
			<li :class="{'on':$route.name == 'Member'}">
				<router-link :to="{name:'Member'}">
					<div class="svg">
						<img :src="$Svg('me.svg')" />
					</div>
					<p>我的</p>
				</router-link>
			</li>
		</ul>
	</footer>
	<template v-if="$route.name != 'Member'"> 
		<div style="display: none;" v-if="$route.name != 'Index'">
	        <Position position="count" />
	    </div>
		<div style="display: none;" v-else>
			<Position position="indexcount" />
		</div>
	</template>
</template>
<style lang='scss' scoped>
footer {
	text-align: center;
	margin-top: 30px;
	.layout {
		// background: rgba(231, 231, 231, 0.6);
		display: block;
		padding-top: 20px;
		padding-bottom: 60px;
		font-size: 12px;
		color: #ccc;
		p {
			padding: 20px 5%;
			display: grid;
			grid-template-columns: auto auto auto auto auto;
			grid-gap: 10px;
			a {
				color: #999;
				font-weight: 400;
				font-size: 14px;
				display: inline-block;
				text-align: center;
				width: 100%;
			}
		}

		.desc {
			font-size: 12px;
			color: #999999;
			letter-spacing: 1px;
			font-weight: 400;
			padding-top: 20px;
			margin-bottom: 10px;
		}

		.copyright {
			font-family: PingFangSC-Regular;
			font-size: 12px;
			color: #999999;
			letter-spacing: 1px;
			font-weight: 400;
			margin-bottom: 50px;
		}
	}

	.menu{
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		display: grid;
		grid-template-columns: auto auto auto auto auto;
		grid-gap: 10px;
		height: 68px;
		background-image: var(--theme);
		padding-top: 10px;
		li{
			text-align: center;
			position: relative;
			opacity: 0.7;
			&:before{
				content: "";
				position: absolute;
				left: 50%;
				top: -10px;
				width: 0;
			    height: 0;
			    margin-left: -8px;
			}
			&.on{
				opacity: 1;
				&:before{
				    border: 8px solid;
				    border-top-width: 5px;
				    border-color: #fff transparent transparent transparent;
				}
			}
			p{
				width: 100%;
				color: #fff;
				font-size: 12px;
				margin:0;
			}
		}
	}
}
</style>