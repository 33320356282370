import store from '@/lib/store'
import axios from 'axios'

// http方法
function httpFn(domain) {
    return axios.create({ 
        credentials: true, 
        baseURL: domain || location.origin, 
        headers:{
            "Content-type": "Application/json",
        },
        timeout: 15000,
    })
}

// 加载广告位渲染
import Position from '@/lib/components/position.vue';
import Page from '@/lib/components/page.vue';

// 日期格式
import datetime from '@/lib/js/date.js'
import useApi from '@/lib/js/api.js'

// 初始化app api地址
function initAppApi(app,obj) {
    obj = obj || {}
    let uri = {}
    if (location.protocol == "file:") {
        // 这种情况目前只在开发环境下发生，应该无需考虑
        let dfHost = "http://127.0.0.1"
        let data = prompt("请输入API服务", dfHost)
        if (data) { obj = new URL(data) }
        uri = new URL(dfHost)
    } else {
        // 默认当前地址
        uri = new URL(location)
    }
    if (obj.protocol) uri.protocol = obj.protocol
    if (obj.hostname) uri.hostname = obj.hostname
    if (obj.port) uri.port = obj.port

    let apiAddr = uri.origin
    if(typeof apiprefix == 'string') apiAddr += apiprefix
    let ajaxHandle = httpFn(apiAddr)
    
    if (app){ // 所有站点公共组件
        app.component("Position",Position);
        app.component("Page",Page);
        app.config.globalProperties.$ajax = ajaxHandle
        app.config.globalProperties.$Time = datetime
    }

    let api = useApi(ajaxHandle)
    return api.getSiteInfo().then(res=>{
        let data = res.data || {}
        store.commit("SiteInfo",data) // 站点信息
        store.commit("SitePub",data.adsMap || {}) // 广告
        return store
    })
}

// console.clear()
console.log("V1.2.0")

export default initAppApi