<template>
	<template v-if="$route.name != 'Member'"> 
        <Position position="foot"/>
		<Position position="indexpic" />
	    <div class="clear"></div>
	</template>
	<footer>
		<div class="layout">
			<!-- <p>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
			</p>
			 本站资源由 <a href="{{$store.state.siteInfo.TechnicalSupport}}" target="_blank" style="color:red">{{$store.state.siteInfo.TechnicalSupport}}</a> 提供 -->
			<div class="desc">本站内容均从互联网收集而来，仅供交流学习使用，版权归原创者所有如有侵犯了您的权益，尽请通知我们，本站将及时删除侵权内容。</div>
			<div class="copyright">Copyright @ 2022 {{$store.state.siteInfo.title}} {{$store.state.siteInfo.domain}} 版权所有</div>
		</div>
		<ul class="tool">
			<li>
				<div class="svg"><img :src="$Svg('kefu.svg')" /></div>
				<p>客服</p>
			</li>
			<li>
				<div class="svg" @click="FN.toApp(true)"><img :src="$Svg('apk.svg')" /></div>
				<p>APK</p>
			</li>
			<li>
				<div class="svg" @click="FN.toApp(false)"><img :src="$Svg('ios.svg')" /></div>
				<p>IOS</p>
			</li>
			<li @click="FN.toTop()">
				<div class="svg"><img class="roate" :src="$Svg('top.svg')" /></div>
			</li>
		</ul>
	</footer>
	<div class="clear"></div>
	<template v-if="$route.name != 'Member'"> 
		<div style="display: none;" v-if="$route.name != 'Index'">
	        <Position position="count" />
	    </div>
		<div style="display: none;" v-else>
			<Position position="indexcount" />
		</div>
	</template>
</template>
<script setup>
	import { reactive, toRefs, ref, getCurrentInstance, onMounted, nextTick, watch } from "vue";
	import { useRouter } from "vue-router";
	let router = useRouter();

	//获取上下文
	const currentInstance = getCurrentInstance()
	const { $ajax } = currentInstance.appContext.config.globalProperties
	
	// api
	import useApi from  '@/lib/js/api.js'
	let api = useApi($ajax)

	const state = reactive({
		menus: [],
		hots: [],
		onName:"",
		domain:"",
		app:{
			"apkVersion":"",
			"apkUri":"",
			"apkCode":1,
			"iosVersion":"",
			"iosUri":"",
			"iosCode":1
		},
	})
	const { menus,onName,hots,domain } = toRefs(state)

	const FN = {
		toTop(){
			$("body,html").animate({scrollTop: 0},480);
		},
		getAppVersion(){
			api.getAppVersion().then(res=>{
				state.app = res.data || {}
			}).catch(err=>{
				console.dir(err.message)
			})
		},
		toApp(isApk){
			if(isApk && state.app.apkUri){
				window.open(state.app.apkUri,"_blank")
				return
			}
			if(!isApk && state.app.iosUri){
				window.open(state.app.iosUri,"_blank")
				return
			}
			alert("敬请期待")
		}
	}

	onMounted(() => {
		FN.getAppVersion()
	})
</script>
<style lang='scss' scoped>
footer{
	width: 100%;
	background: rgba(231,231,231,0.6);
	text-align: center;
	margin-top: 30px;
	.layout{
		display: block;
		margin:0 auto;
		width: var(--width);
		height: 100%;
		padding: 30px 0;
		// p{
		// 	padding: 45px 0;
		// 	a{
		// 		color: #666;
		// 		font-weight: 400;
		// 		margin-right: 30px;
		// 		font-size: 16px;
		// 		&:last-child{
		// 			margin-right: 0;
		// 		}
		// 	}
		// }
		.desc{
			padding-top: 30px;
			padding-bottom: 15px;
			font-size: 14px;
			color: #999999;
			letter-spacing: 1px;
			font-weight: 400;
		}
		.copyright{
			font-family: PingFangSC-Regular;
			font-size: 14px;
			color: #999999;
			letter-spacing: 1px;
			font-weight: 400;
			padding-bottom: 15px;
		}
	}
	.tool{
		position: fixed;
		right:0;
		bottom:10vh;
		background: #fff;
		width:80px;
		border: 1px solid #eee;
		z-index: 10;
		li{
			position: relative;
			padding: 5px 0;
			cursor: pointer;
			&:after{
				content: "";
				position: absolute;
				bottom: 0;
				left: 15px;
				right: 15px;
				border-bottom: 1px solid #eee;
			}
			&:hover{
				background-image: var(--theme);
				p{
					color: #fff;
				}
				img{
					filter: drop-shadow(-25px 0px 0px #fff);
					-webkit-filter: drop-shadow(-25px 0px 0px #fff);
					&.roate{
						filter: drop-shadow(-25px 0px 0px #fff);
						-webkit-filter: drop-shadow(-25px 0px 0px #fff);
					}
				}
			}
			img{
				filter: drop-shadow(-25px 0px 0px var(--svg));
				-webkit-filter: drop-shadow(-25px 0px 0px var(--svg));
				&.roate{
					width:32px;
					height:24px;
					filter: drop-shadow(-25px 0px 0px var(--svg));
					-webkit-filter: drop-shadow(-25px 0px 0px var(--svg));
				}
			}
			p{
				margin-top: 3px;
				color: var(--svg);
				font-size: 11px;
			}
		}
	}
}
</style>