<template>
	<template v-if="$route.name != 'Member'"> 
		<div class="section-banner">
			<Position position="topbanner" v-if="$route.name == 'Index'" />
			<Position position="alltop" v-else />
		</div>
		<div class="clear"></div>
		<Position position="head" />
		<div class="clear"></div>
	</template>
	<header>
		<div class="layout">

			<div class="logo" @click="router.push({name:'Index'})">
				<img class="ico" :src="$Svg('cz/ico.png')" />
				<!-- <img class="ico" :src="$Svg('ico.svg')" /> -->
				<div>
					<img :src="$Svg('cz/name.png')" />
					<!-- <img :src="$Svg('name.png')" /> -->
					<span>{{domain}}</span>
				</div>
			</div>

			<template v-for="(vo,idx) in menus">
				<div class="menu" v-if="vo.meta.menu">
					<template v-for="(sub,idy) in vo.children">
						<router-link :to="{name:sub.name}" :class="{'on':sub.name == onName}" v-if="sub.meta.menu">{{sub.meta.title}}</router-link>
					</template>
					<template v-for="(sub,idz) in $store.state.siteClass">
						<template v-if="sub.type == 1 && sub.status == 1">
							<template v-for="(vosub,idj) in sub.children">
								<router-link :to="{name:'CateList',params:{cid:vosub.id}}" :class="{'on':vosub.id == onName}" v-if="idj < 6">{{vosub.name}}</router-link>
							</template>
						</template>
					</template>
				</div>
			</template>
			<div class="tool">
				<div class="input">
					<input placeholder="输入片名关键字" type="text" v-model="keyword" />
					<span class="search" @click="$router.push({name:'Search',query:{'keyword':keyword}})">
						<img :src="$Svg('search.svg')" />
						搜全网
					</span>
					<div class="select">
						<ul>
							<template v-for="(vo,idx) in hots">
								<li>
									<router-link :to="{name:'VodInfo',params:{cid:vo.categoryChildId,id:vo.id}}" :title="vo.name">
										<el-tag :type="idx < 2 ? 'danger':'warning'" effect="dark" size="small">
											{{idx < 2? '热':'新'}}
										</el-tag>
										{{vo.name}}
									</router-link>
								</li>
							</template>
						</ul>
					</div>
				</div>
				<span class="menber">
					<router-link :to="{name:'Member'}">
						<img :src="$Svg('header.svg')" />
					</router-link>
				</span>
			</div>
		</div>
	</header>

	<!-- 非会员页面显示广告 -->
	<template v-if="$route.name != 'Member'"> 
		<div class="clear"></div>
		<Position position="nextdown" />
		<div class="clear"></div>
		<Position position="menudown" />
		<div class="clear"></div>
		<div class="three"><Position position="topthree" /></div>
		<div class="clear"></div>
		<Position position="mytop" v-if="$route.name != 'Index'" />
		<div class="clear"></div>
	</template>
</template>
<script setup>
	import { reactive, toRefs, ref, getCurrentInstance, onMounted, nextTick, watch } from "vue";
	import {AppRouter} from  '@/jp01/router/index.js'

	import { useRouter } from "vue-router";
	let router = useRouter();

	import { useStore } from "vuex";
	const store = useStore();
	
	//获取上下文
	const currentInstance = getCurrentInstance()
	const { $ajax } = currentInstance.appContext.config.globalProperties
	
	// api
	import useApi from  '@/lib/js/api.js'
	let api = useApi($ajax)

	const state = reactive({
		menus: [],
		hots: [],
		onName:"",
		domain:"",
		logo:"",
	})
	const { menus,onName,hots,domain,logo } = toRefs(state)
	const keyword = ref(null)
	const FN = {
		getList(){
			let data_opt = {
				limit:store.state.num.recommendVideoListNums || 0,
				// rand:true
			}
			api.getVodList(data_opt).then(res => {
				let data   = res.data || []
				state.hots = data.list || []
		    }).then(res=>{
		    })
		},
		getHostName(){
			let siteInfo  = store.state.siteInfo || {}
			state.logo = siteInfo.logoBase64
			if(siteInfo.domain){
				state.domain = siteInfo.domain
			}else if(siteInfo.domainRule && siteInfo.domainRule.contentCurrentDomain){
				state.domain = siteInfo.domainRule.contentCurrentDomain
			}else{
				state.domain = location.hostname
			}
		},
	}

	onMounted(() => {
		state.menus = AppRouter()
		FN.getHostName()
		FN.getList()
	})

	watch(()=>router,(n,o)=>{
		let route = n.currentRoute.value || {}
		keyword.value = route.query.keyword || ""
		if(route.params.cid){
			state.onName = route.params.cid
		}else{
			switch(route.name){
				case "Star":
				case "StarInfo":
					state.onName = "People"
				break;
				case "Topic":
				case "TopicInfo":
					state.onName = "Topic"
				break;
				default:
					state.onName = route.name
			}
		}
	},{immediate:true,deep:true})
</script>
<style lang='scss' scoped>
header{
	width: 100%;
	height: 72px;
	box-shadow: 0px 4px 20px 0px rgba(78,78,78,0.1);
	position: sticky;
	top: 0;
	z-index: 10;
	font-size: 16px;
	.layout{
		background-image: var(--theme);
		display: block;
		margin:0 auto;
		width: var(--width);
		height: 100%;
		position: relative;
		.logo{
			float: left;
			height: 100%;
			display: flex;
			align-items: center;
			padding-left: 10px;
			position: relative;
			cursor: pointer;
			.ico{
				height: 70px;
			}
			div{
				height: 100%;
				img{
					height: 28px;
					margin-top: 10px;
				}
			}
			span{
				position: absolute;
				left: 60px;
				right: 0;
				bottom: 6px;
				text-align: center;
				color:#fff;
				font-size: 24px;
				font-weight: bold;
			}
		}
		.tool{
			position: absolute;
			right: 10px;
			top:15px;
			line-height: 40px;
			display: flex;
			.input{
				position: relative;
				width: 300px;
				height: 40px;
				background: #FFFFFF;
				border-radius: 20px;
				padding:0 10px;
				color: #999;
				input{
					outline: none;
					width:72%;
					height: 30px;
					border: 0;
					border-radius: 5px;
				}
				.search{
					position: absolute;
					top: 3px;
					right: 3px;
					height: 34px;
					padding:0 15px;
					background-image: var(--search);
					border-radius: 20px;
					font-size: 14px;
					text-align: center;
					display: flex;
					align-items: center;
					color: #fff;
					cursor: pointer;
					img{
						margin-right: 5px;
					}
				}
				&:hover{
					.select{
						display: block;
					}
				}
				.select{
					position: absolute;
					left: 15px;
					top:40px;
					width:270px;
					box-shadow: 0px 4px 20px 0px rgba(78,78,78,1);
					display: none;
					padding-top: 5px;
					ul{
						background: #fff;
						li{
							list-style-type: none;
							padding: 0 5px;
							font-size: 14px;
							border-bottom: 1px solid rgba(244,141,114,0.3);
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							color: #999;
							font-weight: 400;
							cursor: pointer;
							opacity: 0.8;
							.el-tag{
								margin-right: 5px;
							}
							&:last-child{
								border-bottom: 0;
							}
							&:hover{
								opacity: 1;
							}
						}
					}
				}
			}
			.menber{
				cursor: pointer;
				margin-left: 20px;
				background-color: #fff;
				width: 40px;
				height: 40px;
				overflow: hidden;
				border-radius: 50%;
				padding:8px;
				a{
					display: inline-block;
				}
				img{
					width: 100%;
					height: 100%;
					transform: translateX(40px);
					filter: drop-shadow(-40px 0px 0px var(--svg));
					-webkit-filter: drop-shadow(-40px 0px 0px var(--svg));
				}
			}
		}
		.menu{
			height: 100%;
			display: flex;
			align-items: center;
			padding-left: 50px;
			max-width: calc(var(--width) - 500px);
			overflow: hidden;
			a{
				color: #fff;
				display: inline-block;
				text-align: center;
				line-height: 40px;
				border-radius: 20px;
				// width: 70px;
				height: 40px;
				padding:0 20px;
				&.on{
					background-image: var(--on);
					border: 2px solid rgba(255,255,255,1);
					box-shadow: 0px 4px 10px 0px rgba(130,130,130,0.1);
				}
			}
		}
	}
}
</style>