<template>
	<header>
		<div class="back">
			<div class="go" @click="$router.go(-1)">
				<el-icon><ArrowLeftBold /></el-icon>
			</div>
			<div class="name">
				<slot></slot>
			</div>
			<!-- <div class="ico">
				<router-link :to="{name:'Search'}">
					<img :src="$Svg('search.svg')" />
				</router-link>
				<router-link :to="{name:'Member'}" v-if="$route.name == 'Index'">
					<img :src="$Svg('header.svg')" />
				</router-link>
				<router-link :to="{name:'Search',query:{c:true}}" v-else>
					<img :src="$Svg('all.png')" />
				</router-link>
			</div> -->
		</div>
	</header>
</template>
<style lang='scss' scoped>
header{
	width: 100%;
	box-shadow: 0px 4px 20px 0px rgba(78,78,78,0.1);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10;
	position: sticky;
	background-image: var(--theme);
	.back{
		margin:0 auto;
		position: relative;
		display: grid;
		grid-template-columns: 80px auto 100px;
		grid-gap: 0;
		height: 50px;
		.go{
			font-size: 24px;
			font-weight: bold;
			text-align: left;
			color: #fff;
			padding-left: 10px;
			padding-top: 13px;
		}
		.name{
			position: relative;
			color: #fff;
			font-size: 16px;
			text-align: center;
			font-weight:bold;
			padding-top: 15px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow:ellipsis;
		}
		.ico{
			text-align: right;
			padding-right: 15px;
			padding-top: 13px;
			a{
				font-size: 0;
				margin-right: 15px;
				&:last-child{
					margin-right: 0;
				}
				img{
					display: inline-block;
					width: 26px;
					height: 26px;
				}
			}
		}
	}
}
</style>