import { createStore } from "vuex";

export default createStore({
	state: {
		dayStart:new Date(new Date(new Date().toLocaleDateString()).getTime()).getTime() / 1000, // 当天零点
		loading:false, // 加载中
		pageKey:10000, // 路由key
		siteInfo:{}, // 站点信息
		num:{}, // 数据数量
		sitePub:{}, // 广告数据
		siteClass:[], // 分类数据
	},
	getters: {},
	mutations: {
		SiteInfo(state, info) {
			state.siteInfo = info || {};
			state.num = info.tplDataNums || {};
		},
		SitePub(state, info) {
			state.sitePub = info || {};
		},
		SiteClass(state, info) {
			state.siteClass = info || [];
		},
		Loading(state, val) {
            state.loading = val || false;
        },
        PageKey(state, sec) {
            state.pageKey = Math.random() * 1000;
        },
	},
	actions: {
	},
})